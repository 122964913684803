import './Psychology.scss';
export default function Psychology() {
  return (
    <div className='psych-container'>
      <h1>The Business of Addiction</h1>
      <p>
        There was a time where large game companies sought to make great games sold as a single package and their strategy to make more money was almost identical to movies today. Make a great product that people like and will want more of (also sell merchandise, obviously). This strategy is still employed frequently, but is no longer the only strategy.
      </p>
      <p>
        As we've explored in the other sections of this project, there has been more of a focus on making a game a business product rather than an experience crafted with care and passion. That's not to say games aren't crafted with passion, and we asbolutely see creative, innovative games. However, there is a dangerous precedent being set where many games have become a product, tapping into the human psychology to exploit emotions of players to get their attention and money and to also keep it for an extended period of time.
      </p>
      <h2>
        Recipe for an addictive game
      </h2>
      <p>
        Today, we're going to make an addictive game using a lot of the techniques employed to keep players hooked and opening their wallets. This recipt dates back to the every 2010 decade where Facebook games were becoming a favourite among the new social network. My Dad used to enjoy these games and so did I - as a kid, I was particularly vulnerable to falling victim to these hooks and on occasion I even opened my wallet (or my parents' wallet) so I could keep up with other players and have better virtual stuff.
      </p>
      <p>
        Today the recipe has evolved to encompass a wider variety of games and has proven very effective in pretty much every market. From mobile games, to Facebook games, to mainstream AAA games, you are bound to entice a wide target audience and keep them captivated for a long time if you follow these steps.
      </p>
      <p>
        Needless to say, this generational recipe is phenomenal and if you make it correctly and really capitalise on the foibles of human psychology, you will absolutely reap many rewards for you and your company. 
      </p>
      <h3>
        Ingredients
      </h3>
      <ul>
        <li>
          Marketing campaign (dedicated team and incessant advertising - costly but worth it to get your product off the ground!)
        </li>
        <li>
          Notifications (at least 1 every few hours if possible, more is okay with the right target audience)
        </li>
        <li>
          In game currency (you want to make it easy to have at least a few thousand dollars so the average person feels more successful in your game than in real life)
        </li>
        <li>
          Social heirarchy
        </li>
        <li>
          Loot boxes
        </li>
        <li>
          Rewards for streaks
        </li>
        <li>
          Punishment for breaking streaks or not returning frequently enough
        </li>
        <li>
          Random rewards after missions/tasks that are very rarely good
        </li>
        <li>
          Events - sales or gameplay (once a week is good, once a day can also work)
        </li>
        <li>
          Time locks for core gameplay with the option to pay to bypass it
        </li>
        <li>
          Add in a splash of fun every now and then
        </li>
      </ul>
      <h3>
        Method:
      </h3>
      <ol>
        <li>
          <p>
            Marketing will really be a vital part of making this recipe work and the way you go about it will depend on your target audience.
          </p>
          <p>
            Mobile audience: You'll want to pay for ads everywhere, and I mean everywhere. Make sure you have some sounds and visuals that grab people's attention. If the style is more of a puzzle game, be sure to show gameplay doing a puzzle wrong with an obvious solution most viewers will pick up on - this will make them feel confident about doing a better job and go ahead and install your product.
          </p>
          <p>
            Desktop audience: For this audience you want to take a different approach by trying to get a following from streamers and YouTubers. Advertising is still useful, but the real key is paying respected gamers in the community to have fun in your game and displaying that to the world.
          </p>
        </li>
        <li>
          You absolutely must have in game currency and many items to buy with that currency. You can take it to the next level and create a pay to win game - just be warned, you will likely push away casual gamers, but many players will be happy to drop a few hundred dollars to thousands just to keep ahead of the competition. A more neutral approach would be to sell items that do not affect gameplay such as skins or mounts and making these limited edition will help in making players want to cash in on the latest trend.
        </li>
        <li>
          Rewards and punishments get those emotions flowing. Be sure to have a daily reward for each day a user plays the game, this can be a loot box, currency, items or even a badge. As their streak gets longer, make these items better so they feel it would be a great loss to miss a day. On the flipside, have a negative impact for missing a certain period of time. If the player has to maintain something in game, such as a farm, their crops could wither. This will coerce them to not miss a day in the future, but be careful with this, too harsh a penalty and you could drive them away. Remember, these rewards and penalties are not real, they're just code, but you want them to feel real to the player.
        </li>
        <li>
          Loot boxes are essentially gambling in a kid friendly format. Done right, the suspense of just opening a box will be addicting enough. You want to make sure you give a few pretty nice rewards early on to set the false idea that your loot boxes are always a good investment. Making sure you can get more lootboxes either by spending more time in the game (say, getting one every match) or by paying for them, either way you're opening their keeping them hooked or opening their wallet. 
        </li>
        <li>
          Having a social heirarchy is dependent on taste and not always important, it also depends on the style of your product. Generally, the way this works is to have some sort of group players can get involved in (a faction or clan), the great benefit of this is that it allows players to bond with each other over your game. The social heirarchy component essentially creates positions or tiers in these groups - people at the top will savour the respect of their fake title that won't mean much and people at the bottom will desire that title and try to earn it, consequently by playing your game more.
        </li>
        <li>
          Time locks drag out gameplay and encourage players to return as soon as the time has elapsed so they don't feel like they wasted time not unlocking it sooner and starting the next time lock. This is amazingly effective - it could be as simple as having to wait a period of time to open a lootbox, repair an item or complete an activity.
        </li>
        <li>
          Events should be designed to draw in your entire playerbase and in some instances, it should be clear that missing the event will be a disadvantage for the player as they'll fall behind others. 
        </li>
        <li>
          So far, you've made a pretty impressive game that taps into the complex psychology of a human and exploits the weaknesses in a way that leads to more profits for you and your company. However, we can't miss the core crucial factor and quite possibly the hardest part of this recipe. You have to make your game fun to play. A game that people don't enjoy will not attract repeat business, so don't skimp out on this one.
        </li>
        <li>
          We've almost finished our game, but we haven't covered everything. You're going to lose some players over time and while they won't always be the ones spending money, a loss of any number of players will result in a gradual dwindling of numbers and profts. You want players to feel like they are going to have a completely new experience every now and then. To do this, you can have seasons that allow for additional gameplay, new items/characters/game modes, etc. It's also wise to make this a big deal in your game. This is a pretty recent addition to this recipe, but it's one you can't afford to leave out if you want to keep filling your pockets for years to come with your product.
        </li>
      </ol>
      <p>
        There we have it, your game is just about ready to draw in thousands of people who will hopefully spend a lot of time in your game and willingly open their wallets for you. All that just for knowing a bit of psychology and exploiting where humans are weakest. The best part is that all of these ingredients are virtual and only stand to benefit you!
      </p>
      <h2>
        The realities of this approach
      </h2>
      <p>
        Unfortunately in today's gaming culture, these games are far too prevalent for all demographics and target audiences. Mobiles games like Clash Royale prey on vulnerable kids to young adults using the above recipe. Games like fortnite appeal to a slightly older audience.  But no one is left out, the middle age to older generation can also be exploited and often are in the form of facebook games that offer a simpler gameplay, but again follow the above recipe (Farmville, Battle Pirates, etc.). My Dad introduced me to Battle Pirates and over the time I played it, the above recipe became increasingly more aggressive - if you missed even a week of gameplay you'd be at a severe disadvantage and without paying you'd never be able to catch up with players ahead of you. I knew players who had spent thousands of dollars to be at the top and it became a desparate race to have all the best items first.
      </p>
      <p>
        This predatory style of creating games does not come without a cost for the average player and gaming as a whole. Lives fall apart because of addiction to games - partners leave, grades drop, responsibilities become a lower priority. Spending money on virtual items and small dopamine rushes also have vast negative impacts on the average person's life - it can lead to a devastating spiral of addiction, becoming broke and developing depression.
      </p>
      <p>
        In terms of the shift of gaming, the impact of this profitable form of business is huge. Its not clear where the future will lead for this style of game or what will become of the more traditional style where a game is made to be enoyable and sold as one whole package.
      </p>
      <h2>
        What's the solution?
      </h2>
      <p>
        Lootboxes, virtual items, in game currencies and daily streaks will never go away - they are profitable and effective in keeping a strong playerbase. In many cases, players are able to responsibly enjoy these features without the negative impacts in the same way that people can responsibly enjoy gambling without taking it too far and still experience the positive emotions from the activity.
      </p>
      <p>
        As a gaming community, a focus needs to be put on educating the upcoming generations. They need to become familiar with human psychology as a young teenager before they become massively targeted by predatory corporations. This is true not only in gaming, but in every aspect of life where a company is trying to get their attention. Why do they want your attention? What methods are they using to get it and how do they work? Ultimately, companies will spend a lot of money on research into how to make their games more addictive and it's why the above recipe is so effective. These companies have the high ground when it comes to understanding this and everyone needs to be informed enough to be able to make rational, responsible decisions that will not have a drastic negative impact on their lives.
      </p>
    </div>
  );
}
