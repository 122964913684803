import { Gallery } from "../../Gallery";

import { CONTENT } from "./galleryContent";

import memeDLC from '../../../images/Intro/dlcmeme.jpg';

export default function Intro(props) {
  return (
    <div>
      <h1>
        From Passion to Product
      </h1>
      <p>
        Video games initially were made to push the limits of what was possible with computers to make something fun that had an objective and actually resembled a game.
      </p>
      <p>
        As hardware and software competed, new and exciting ideas could be explored and crafted into a game. Games were an art form only made possible by a complex piece of hardware and were sweeping the world by storm. Today's hardware hardly has limits - if you can think it, it can be or likely has been made.
      </p>
      <p>
        Though with great power comes great responsibility. Games have evolved and so have the companies that make them. Gone are the days where games were made to test the limits of hardware and strive to make a game innovative and exciting that would drive up sales. Where a buck can be made, someone will be trying to make it and thus, in today's day and age, the companies that makes games have largely shifted their focus to a more financial, business-oriented approach.
      </p>
      <p>
        While many of the changes brought about by this shift of focus are not inheritly bad, there have been numerous examples of companies falling short and revealing their less appealing underlying motives. This has had a massive effect on gaming culture and has spawned new genres, controversies, esports categories and boycott campaigns.
      </p>
      <p>
        What are the ways in which companies have changed their focus? Where have these failed or succeeded? What is the impact on gaming culture and the gaming community? All will be discussed in this project.
      </p>
      <h2>
        DLC
      </h2>
      <p>
        Games now a days will usually have some additional content that players can access through purchasing with real money. This is called ‘downloadable content’ or DLC for short and is bought in addition to purchasing the full game which usually costs $60 - $100 (with DLC generally costing anywhere from $10 to $100).
      </p>
      <div style={{ width: '40%', margin: '0 auto 0' }} >
        <img src={memeDLC} alt ="" style={{ width: '100%' }} />
      </div>
      <p>
        In some cases a game will release with a weak or unfinished base game which encourages the player to pay for any DLCs or extra content so that their initial purchase isn’t wasted. This is deceptive of game companies to do because they essentially selling an unfinished game at full price. This can be seen with Destiny and Destiny 2.
      </p>
      <p>
        This presentation is brought to you in the form of a base assignment (this page) with multiple DLCs you can purchase with compliments. Don’t be stingey.
      </p>
      <p style={{ margin: '20px 0 0 0' }}>
        <em>(Click on images to see more)</em>
      </p>
      <Gallery contentData={CONTENT} />
    </div>
  );
}