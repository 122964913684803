import { convertAniBinaryToCSS } from 'ani-cursor';
import './Cursor.scss';

async function applyCursor(selector, aniUrl) {
  const response = await fetch(aniUrl);
  const data = new Uint8Array(await response.arrayBuffer());

  const oldStyle = document.getElementById('cursor-style');
  if (oldStyle) {
    oldStyle.parentNode.removeChild(oldStyle);
  }

  const style = document.createElement('style');
  style.innerText = convertAniBinaryToCSS(selector, data);
  style.id = 'cursor-style';

  document.head.appendChild(style);
}

export default function Cursor({ cursor, unlocked }) {
  const updateCursor = () => {
    if (cursor.type === 'ani') {
      applyCursor('body', cursor.file);
      return;
    }
    const style = document.getElementById('cursor-style');
    if (style) {
      style.parentNode.removeChild(style);
    }
    document.documentElement.style.cursor = `url(${cursor.file}), auto`;
  }

  return (
    <div
      className='cursor-container'
      onClick={() => unlocked && updateCursor()}
      style={{
        filter: `brightness(${ unlocked ? '100%' : '10%'})`,
      }}
    >
      <img className='cursor-icon' src={cursor.img} alt="" />
    </div>
  )
}