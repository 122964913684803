import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import './Gallery.scss';

export default function Gallery({ contentData }) {
  const [content, setContent] = useState(contentData[0]);

  return (
    <div className="gallery">
      <div className="scrollable">
        <Scrollbars>
          <div className="gallery-thumbnails">
            {contentData.map((con, i) => (
              <div
                className="gallery-thumbnail"
                key={i}
                style={
                  {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    backgroundImage: `url(${con.thumbnail})`,
                    
                  }
                }
                onClick={()=>setContent(contentData[i])}
              >
                
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
      <div className="gallery-view">
        {content.main}
      </div>
    </div>
  )
}