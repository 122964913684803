import { Gallery } from "../../Gallery";

import { CONTENT } from "./galleryContent";

import imageSkin from '../../../images/Micro/skin1.jpg';
import imageSkin2 from '../../../images/Micro/skin2.png';

export default function Microtransactions() {
  return (
    <div>
      <h1>Microtransactions</h1>
      <h2>Skins</h2>
      <p>
        Free to play games will often give the player the option to purchase a ‘skin’ for their characters or weapons. This <b>doesn’t increase the player’s skill nor give them an advantage</b>. This allows the player to <b>support the creators</b> while also changing their avatar’s appearance.
      </p>
      <div style={{ display: "flex", justifyContent: "center", gap: '20px' }}>
        <img src={imageSkin} alt="" />
        <img src={imageSkin2} alt="" />
      </div>
      <p>
        The chest to the right is an example of a loot box. Sometimes these can be bought so you have more chances of opening something good (similar to buying scratch cards). Or in many cases, you'll have to wait a certain period of time before opening one which encourages the player to come back soon so they can start the next unlock (or you can pay to speed up the unlock).
      </p>
      <p>
        Sometimes in games you can unlock a skin by way of <b>completing achievements</b> or missions. These skins became a way of being able to <b>identify strong players</b> and <b>’flex’</b> your abilities. More recently though, some games have had the option to <b>buy those same skins</b>. This still does not affect a player’s skills or chances in a game, but it makes the <b>accomplishments</b> of the player who unlocked the skin without purchases <b>less recognised</b> because other players don’t know if you earnt it or bought it.
      </p>
      <Gallery contentData={CONTENT} />

    </div>
  );
}