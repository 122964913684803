import { Compliments } from ".";
import { useInteractionBoxUpdate } from "../../api/InteractionBoxContext";

import './IconDLC.scss';

export default function IconDLC({ unlock, unlocked, redirect, section, canUnlock }) {
  const { addInteractionBox } = useInteractionBoxUpdate();

  const openComplimentBox = () => {
    if (unlocked) {
      redirect();
      return;
    };
    addInteractionBox((closeUI) => (
      <Compliments
        unlock={unlock}
        closeUI={closeUI}
        redirect={redirect}
        canUnlock={canUnlock}
      />
    ))
  }

  return (
    <>
      <div
        className="dlc-icon"
        role="button"
        tabIndex="0"
        onClick={() => openComplimentBox()}
        onKeyUp={(e) => e.key==='Enter' && openComplimentBox(true)}
        style={
          {
            backgroundImage: `url(${section.thumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }
        }
      >
        {!unlocked &&
        (
          <div className="dlc-locked" />
        )}
        <h3
        style={
          {
            zIndex: (unlocked ? 10 : -1),
          }
        }>
          {(!unlocked ? 'Unlock ' : '') + section.text}
        </h3>
      </div>
    </>
  )
}