import './NewCursor.scss';

export default function NewCursor({ image, close }) {
  return (
    <div
      className='newcursor-container'
      onClick={() => close()}
    >
      <h2>Cursor unlocked!</h2>
      <img src={image} alt="" />
      <p>Click anywhere to close</p>
    </div>
  )
}