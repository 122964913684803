import { Gallery } from "../../Gallery";

import { CONTENT } from "./galleryContent";

import preorderImage from '../../../images/Management/predorder.jpg';

export default function Management() {
  return (
    <div>
      <h1>Management</h1>
      <h2>Deadlines</h2>
      <p>
        Deadlines are good in <b>the business world</b>, they create a structure and ensure that jobs are finished on time. The game industry however is a <b>creative industry</b> and also requires extra time for debugging and unexpected surprises. The game should be completed before release which might end up giving the developers more time to polish the game up.
      </p>
      <p>
        This is a reason why <b>indie games are gaining in popularity</b>, because the developers put more time, effort and appreciation in to it and are releasing complete (or at least close to complete) games.
      </p>
      <p>
        To solve this issue management of the AAA game companies need to consider deadlines to be goals and <b>not potential release dates</b>. However it is unlikely management of these companies will adopt this mindset or even care, so the next best thing to do would be to <b>boycott unfinished games</b> and support indie games that are released completed.
      </p>
      <Gallery contentData={CONTENT} />
      <h2>Preorders</h2>
      <p>
        Preordering a game means that the company can sell a concept of a game to see how many people want the game and the purchasers can receive the game quicker.
      </p>
      <p>
        However, this causes a <b>big problem!</b> This problem is that these <b>games no longer need to do well</b>, the companies have already made a profit.
      </p>
      <img src={preorderImage} alt="" style={{ float: 'left', marginRight: '10px' }} />
      <p>
        Preorders aren’t a very safe way of purchasing games because as there are <b>no reviews</b> out for the game yet the purchaser has no way of knowing the quality that they’re paying for.
      </p>
      <p>
        With this in mind managers are less inclined to try to release and well polished completed game and are more likely to release a game that’s still in the progress of development because it <b>will sell better at a certain time of year</b> or because they want to appear as though they are keeping their deadline promises.
      </p>
    </div>
  );
}  