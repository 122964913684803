import { useState } from "react"
import { ASSIGNMENT_SECTIONS } from "../sections"

import { IconDLC, Lootbox } from "../interactives";

import './ProjectPage.scss';

export default function ProjectPage() {
  const [section, setSection] = useState(ASSIGNMENT_SECTIONS[0]);
  const [unlockedSections, setUnlockedSections] = useState(ASSIGNMENT_SECTIONS.map((_, i) => (i === 0)));

  const unlock = (ind) => {
    setUnlockedSections(unlockedSections.map((bool, i) => (bool || i === ind)));
  }

  const canUnlock = (ind) => {
    if (ind < unlockedSections.length - 1) {
      return true;
    }
    if (unlockedSections.slice(0, unlockedSections.length - 1).every(Boolean)) {
      return true;
    }
    return false;
  }

  return (
    <div className="project-container">
      <Lootbox />
      <div className="section-container">
        {section.component()}
      </div>
      <div className="dlc-container">
        {ASSIGNMENT_SECTIONS.map((section, i) => (
          <IconDLC
            key={i}
            unlock={() => unlock(i)}
            unlocked={unlockedSections[i]}
            redirect={() => setSection(ASSIGNMENT_SECTIONS[i])}
            section={section}
            canUnlock={canUnlock(i)}
          />
        ))}
      </div>
    </div>
  )
}