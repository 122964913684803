import { v4 as uuidv4 } from 'uuid';

import { Intro } from './Intro';
import { Microtransactions } from './Micro';
import { Management } from './Management';
import { Psychology } from './Psychology';
import { Conclusion } from './Conclusion';

import placeholder from '../../images/placeholder.jpg';

import thumbMicro from '../../images/thumbnails/micro.jpg';
import thumbManagement from '../../images/thumbnails/management.png';
import thumbPsych from '../../images/thumbnails/psych.png';

export const ASSIGNMENT_SECTIONS = [
  {
    id: uuidv4(),
    component: (props) => <Intro {...props} />,
    thumbnail: placeholder,
    text: 'Base Assignment',
  },
  {
    id: uuidv4(),
    component: (props) => <Microtransactions {...props} />,
    thumbnail: thumbMicro,
    text: 'Microtransactions',
  },
  {
    id: uuidv4(),
    component: (props) => <Management {...props} />,
    thumbnail: thumbManagement,
    text: 'Management',
  },
  {
    id: uuidv4(),
    component: (props) => <Psychology {...props} />,
    thumbnail: thumbPsych,
    text: 'Psychology',
  },
  {
    id: uuidv4(),
    component: (props) => <Conclusion {...props} />,
    thumbnail: placeholder,
    text: 'Conclusion',
  },
];
