import { useEffect, useState } from 'react';
import Cursor from './Cursor';
import NewCursor from './NewCursor';

import './Lootbox.scss';

import open from '../../images/lootboxopen.png';
import closed from '../../images/lootboxclosed.png';

import curInitial from '../../cursors/initial.cur';

import curLightning from '../../cursors/lightning.ani';
import curAmongUs from '../../cursors/amongus.ani';
import curCool from '../../cursors/cool.ani';
import curCute from '../../cursors/cute.ani';
import curPokeball from '../../cursors/pokeball.ani';
import curGreatPokeball from '../../cursors/greatpokeball.ani';
import curRainbow from '../../cursors/rainbow.ani';
import curNyan from '../../cursors/nyan.ani';

import curMinecraft from '../../cursors/minecraft.cur';
import curMillenium from '../../cursors/milleniumfalcon.cur';
import curPencil from '../../cursors/pencil.cur';
import curSpiderman from '../../cursors/spiderman.cur';
import curLightsaber from '../../cursors/lightsaber.cur';
import curCookie from '../../cursors/cookie.cur';
import curCOD from '../../cursors/cod.cur';
import curPSP from '../../cursors/psp.cur';
import curGameBoy from '../../cursors/gameboy.cur';
import curFace from '../../cursors/face.cur';

import imgLightning from '../../cursors/lightning.png';
import imgAmongUs from '../../cursors/amongus.png';
import imgCool from '../../cursors/cool.png';
import imgCute from '../../cursors/cute.png';
import imgPokeball from '../../cursors/pokeball.png';
import imgGreatPokeball from '../../cursors/greatpokeball.png';
import imgRainbow from '../../cursors/rainbow.png';
import imgNyan from '../../cursors/nyan.png';

const lootboxTime = 60;

const CURSORS = [
  {
    type: 'ani',
    file: curCute,
    img: imgCute,
  },
  {
    type: 'ani',
    file: curAmongUs,
    img: imgAmongUs,
  },
  {
    type: 'cur',
    file: curMinecraft,
    img: curMinecraft,
  },
  {
    type: 'cur',
    file: curFace,
    img: curFace,
  },
  {
    type: 'cur',
    file: curCOD,
    img: curCOD,
  },
  {
    type: 'ani',
    file: curCool,
    img: imgCool,
  },
  {
    type: 'cur',
    file: curPSP,
    img: curPSP,
  },
  {
    type: 'cur',
    file: curGameBoy,
    img: curGameBoy,
  },
  {
    type: 'cur',
    file: curSpiderman,
    img: curSpiderman,
  },
  {
    type: 'cur',
    file: curPencil,
    img: curPencil,
  },
  {
    type: 'cur',
    file: curLightsaber,
    img: curLightsaber,
  },
  {
    type: 'cur',
    file: curCookie,
    img: curCookie,
  },
  {
    type: 'ani',
    file: curPokeball,
    img: imgPokeball,
  },
  {
    type: 'ani',
    file: curRainbow,
    img: imgRainbow,
  },
  {
    type: 'ani',
    file: curGreatPokeball,
    img: imgGreatPokeball,
  },
  {
    type: 'ani',
    file: curLightning,
    img: imgLightning,
  },
  {
    type: 'ani',
    file: curNyan,
    img: imgNyan,
  },
  {
    type: 'cur',
    file: curMillenium,
    img: curMillenium,
  }
];

export default function Lootbox() {
  const [lootboxOpen, setLootboxOpen] = useState(false);
  const [lootboxHover, setLootboxHover] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(lootboxTime);
  const [restartTimer, setRestartTimer] = useState(false);
  const [unlockedCursors, setUnlockedCursors] = useState(CURSORS.map(_ => false));
  const [cursorUnlocked, setCursorUnlocked] = useState(null);

  if (!document.documentElement.style.cursor) {
    document.documentElement.style.cursor = `url(${curInitial}), auto`;
  }

  useEffect(() => {
    let time = 0;
    setTimeElapsed(lootboxTime);
    const timer = setInterval(() => {
      setTimeElapsed(lootboxTime - time - 1);
      time += 1;
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, [restartTimer]);

  useEffect(() => {
    if (timeElapsed <= 0) {
      setLootboxOpen(true);
    }
  }, [timeElapsed]);

  const unlockRandomCursor = () => {
    const lockedCursors = unlockedCursors.map((bool, i) => [i, bool]).filter(([_, bool]) => bool === false);
    const random = lockedCursors[Math.floor(Math.random() * lockedCursors.length)];
    const updatedCursors = unlockedCursors.map((bool, i) => ( i === random[0] ? true : bool));
    setUnlockedCursors(updatedCursors);
    setCursorUnlocked(random[0]);
  }

  const openLootbox = () => {
    if (unlockedCursors.filter(bool => bool === false).length > 0) {
      unlockRandomCursor();
      setRestartTimer(!restartTimer);
      setLootboxOpen(false);
    }
  }

  return (
    <>
      {cursorUnlocked !== null
      &&
      (
      <NewCursor
        image={CURSORS[cursorUnlocked].img}
        close={() => setCursorUnlocked(null)}
      />
      )}
      <div
        className="lootbox-container"
      >
        <div className="lootbox-icon" >
          <div
            className="lootbox"
            onMouseEnter={() => lootboxOpen && setLootboxHover(true)}
            onMouseOver={() => lootboxOpen && setLootboxHover(true)}
            onMouseLeave={() => setLootboxHover(false)}
            onClick={() => lootboxOpen && openLootbox()}
            style={
              {
                backgroundImage: `url(${lootboxHover ? open : closed})`,
                filter: `brightness(${lootboxOpen ? '100%' : '10%'})`,
              }
            }
          />
          {timeElapsed > 0 &&
          (
            <h3 className="lootbox-timer">
              {timeElapsed}
            </h3>
          )}
        </div>
        <div className="cursors-container">
          {
            CURSORS.map((obj, i) => (
              <Cursor
                key={i}
                cursor={obj}
                unlocked={unlockedCursors[i]}
              />
            ))
          }
        </div>
      </div>
    </>
  )
}