import { v4 as uuidv4 } from 'uuid';

import thumbNMS from '../../../images/Management/nms.jpg';
import thumbCP from '../../../images/Management/cp.jpg';

import imageCP1 from '../../../images/Management/cp-1.jpg';
import imageCP2 from '../../../images/Management/cp-2.jpg';
import imageCP3 from '../../../images/Management/cp-3.jpg';
import imageCP4 from '../../../images/Management/cp-4.jpg';
import imageCP5 from '../../../images/Management/cp-5.jpg';

import imageNMS from '../../../images/Management/nms2.jpg';

export const CONTENT = [
  {
    id: uuidv4(),
    thumbnail: thumbCP,
    main: (
      <div>
        <h2>
          Cyberpunk 2077
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <p>
              Before release the game gained a lot of <b>hype</b> as it was made by the same company as The Witcher 3 (successful game) and had an <b>interesting premise</b>. This game suffered from a few pushed back release dates but ended up <b>releasing</b> it unfinished anyway. There is speculation that the game was released because they had seen EA and other AAA companies getting away with it.
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
            <img src={imageCP1} alt="" />
            <img src={imageCP2} alt="" />
          </div>
          <div style={{ width: '75%' }}>
            <p>
              On release this game showed an incredible amount of <b>bugs</b>, a lot of which were <b>detrimental to the overall gameplay experience</b>. This included the players car collisions to be majorly bugged, throwing the player high in the air or even under the map where there was no way out. The car would sometimes even blow up after minimal impact. Another notable bug was the police’s interaction with the player and how they would seemingly teleport to the player’s location. This game also had a lot of minor bugs as well like things teleporting into NPCs hands, NPCs faces not rendering in, late rendering of objects close by, and your character glitching out of the car (visually) with no pants on!
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <img src={imageCP3} alt="" style={{ flexGrow: '1 '}} />
            <img src={imageCP4} alt="" style={{ flexGrow: '1 '}} />
            <img src={imageCP5} alt="" style={{ flexGrow: '1 '}} />
          </div>
          <p>
            However when ignoring the initial release bugs, this game held a lot of good content. The world was well built, the objectives were creative and interesting. By march after it was released the game had been fixed up and polished to the standard it should have been when releasing it. This was a shame because it meant it the game had been delayed so much as another month or so, then the game would have been known for all the good aspects instead of it’s infamous bugs.
          </p>
        </div>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbNMS,
    main: (
      <div>
        <h2>
          No Man's Sky
        </h2>
        <p>
          No man’s sky developers <b>deceived</b> the players when marketing for this new game. This game is one of procedural generation which you could explore, discover, trade and gather. However when marketing for this game the developers also mentioned <b>factions</b> within space, galactic wars that you could be apart of, being able to see <b>other players online</b>, ships would have <b>unique mechanics</b> depending on who built it, and the ability to see the <b>economy</b> and interactions of species on each planet thrive and develop. These features were <b>missing or majorly lacking</b> in the released game. 
        </p>
        <img src={imageNMS} alt="" style={{ float: 'left' }} />
        <p>
          This angered a lot of the people who bought the game because it was <b>priced at the same value as a AAA</b> game which this was not. This was an indie game which was made by a team of only 15 people. This team of people advertised for a game more advanced than they could make.
        </p>
        <p>
          In the initial release the game also crashed a lot, which indicates that perhaps it wasn’t ready to be released. The game felt unfinished to say the least <b>and only now, 4 years after release</b>, the game is to a standard that somewhat delivers on it’s promises
        </p>
      </div>
    )
  }
];