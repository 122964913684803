import { v4 as uuidv4 } from 'uuid';

import thumbBO4 from '../../../images/Intro/cod.jpg';
import thumbPokemon from '../../../images/Intro/pokemon.jpg';
import thumbWitcher from '../../../images/Intro/witcher.jpg'
import thumbDestiny from '../../../images/Intro/destiny.jpg';
import thumbSpiderman from '../../../images/Intro/spiderman.jpg';

import imageBO4 from '../../../images/Intro/bo4.jpg';

import imagePoke from '../../../images/Intro/pokesands.jpg';

import imageSpiderman from '../../../images/Intro/sp.png';
import imaged1 from '../../../images/Intro/d1.jpg';
import imaged2 from '../../../images/Intro/d2.jpg';
import imaged3 from '../../../images/Intro/d3.jpg';

export const CONTENT = [
  {
    id: uuidv4(),
    thumbnail: thumbBO4,
    main: (
      <div>
        <h2>
          Call of Duty: Black Ops IV
        </h2>
        <p>
          This <b>full priced</b> game sold at $80 on launch, and the black ops pass was an extra $60 on top of that <b>straight away</b>. This was the only way to get the new DLC zombies map and have access to 4 other multiplayer maps. 
        </p>
        <p>
          This meant that you might miss out on playing with your friends if they were playing on an excusive map. 
        </p>
        <img src={imageBO4} alt="" style={{ float: 'left' }} />
        <p>
          Black Ops used to sell their new zombies maps <b>individually</b> which allowed the player to <b>choose not to buy</b> maps that ‘suck’ and would therefore allow the player to choose to save their money if they chose. However in Black Ops IV the devs had switched it up so that you were forced to pay for all of the maps regardless of their quality if you wanted access to a new map.
        </p>
        <p>
          More on Black Ops later...
        </p>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbPokemon,
    main: (
      <div>
        <h2>
          Pokemon: Sword and Shield
        </h2>
        <p>
          Pokémon sword and shield differ from previous Pokémon games in that at the release of the game they didn’t have all the previous Pokémon from previous games in the game. Then they added over 200 of those left out Pokémon into their game which you couldn’t catch without purchasing the DLC.
        </p>
        <img src={imagePoke} alt="" style={{ float: 'left' }} />
        <p>
          The only way to obtain those Pokémon was to <b>buy the DLC</b> (which allows you to capture the Pokémon in game in the new areas) but <b>if you don’t</b> purchase then the player has to get the Pokémon by either <b>subscribing</b> to the Pokémon home (a subscription service to hold your Pokémon online) to trade it over from an older version of the game or to trade it with players who did purchase the DLC.
        </p>
        <p>
          Even with this update they still didn’t include all of the Pokémon from previous games. (which again, hadn’t happened in the past)
        </p>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbWitcher,
    main: (
      <div>
        <h2>
          The Witcher 3
        </h2>
        <p>
          Already an expansive game with a story and side missions that kept players busy for over 100 hours, this game was all around well praised by the gaming community. This game was released with nothing missing, nothing behind a paywall and you didn't need anything extra to enjoy it or feel part of the Witcher community.
        </p>
        <p>
          CD Projekt Red also released two DLCs that offered many more hours of gameplay in story, side quests and items. This set a shining example of well-recieved DLCs that didn't feel like a money grab and actually created a fresh new experience for people who enjoyed the initial release.
        </p>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbDestiny,
    main: (
      <div>
        <h2>
          Destiny 2
        </h2>
        <p>
          Destiny 2 had a campaign that was <b>quick</b> and <b>felt unfinished</b>. To continue to enjoy the world there was an optional expansion pass that would allow players to <b>continue the story</b> in new areas with new character interactions. This pass cost an additional $40 to the player <b>on top of</b> it’s initial release price of $60. 
        </p>
        <img src={imaged1} alt="" style={{ float: 'left' }} />
        <p>
          The <b>purchasing of cosmetics</b> such as skins and wearable are usually considered an adequate reason for some microtransactions however the team for Destiny purposely made the base colour of gear to be <b>drab</b> and <b>desaturated</b>. To colour your gear you either need to use the ones you earn but have only a single use or you could purchase the skins and colours. The <b>game ended up just looking dull</b> if you didn’t purchase the skins which was an interesting, but <b>mostly detrimental</b>, strategy by Bungie (developer company).
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px'}}>
          <img src={imaged2} alt="" />
          <img src={imaged3} alt="" />
        </div>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbSpiderman,
    main: (
      <div>
        <h2>
          Spider-man PS4
        </h2>
        <p>
          In contrast to the other games listed here Spiderman is a terrific example of what a game company <b>should do.</b>
        </p>
        <p>
          When Spiderman came out it <b>came out fully completed</b> and you didn’t need to buy anything new and there were minimal bugs on release. 
        </p>
        <p>
          This game has released a few DLC packs which have new missions and suits to go with them. These missions are thought out and contain a range of content. There are so far 3 DLC packs, which cost $12 each or you could purchase all three for $30.
        </p>
        <img src={imageSpiderman} alt="" style={{ width: '400px', margin: '20px auto 20px', display: 'block' }} />
        <p>
          Later in the game when they would release new skins, they would make them available to players for <b>free</b> as it wasn’t considered a new exclusive DLC item, it was just seen as it should have been base game.
        </p>
        <p>
          The Spiderman team <b>really cared</b> for this game, and it shows.
        </p>
      </div>
    )
  }
];