export default function Conclusion() {
  return (
    <div>
      <h1>Conclusion</h1>
      <p>
        The trend of large companies shifting to a more business-centric idealogy has had some detrimental effects on gaming culture and the gaming community. Focus has moved away from making good games people will enjoy and want more of, to using effective strategies and techniques to make more profits.
      </p>
      <p>
        The use of microtransactions, DLCs and other methods employed are not always negative however. In many instances they and give players the opprtunity to get a little more content out of a game they enjoy while also supporting the creators that made the game. The Witcher 3 had good DLC that expanded on the game and was well-recieved by the gaming community, for example.
      </p>
      <p>
        This behaviour really becomes an issue when it impedes creativity by taking the spotlight away from more innovative games and also when it is a clear cash grab with the facade of a game.
      </p>
      <br />
      <p>
        The next generation of gamers should be made aware of all possible intentions behind any company's actions and be cautious when choosing to reach into their wallets or spend another hour playing a game made to be addictive. Thankfully, awareness is increasing and attempts to exploit the gaming community in any form are generally met with disapproval. EA's Battlefront II is a shining example of this - in defending their decision to have characters that cost $100 or 40 hours of gameplay to unlock, they earned themselves the most downvoted comment on Reddit with -700000 karma.
      </p>
      <p>
        The future of gaming lies in the gamer community's hands, not AAA game producing companies and these companies are starting to realise this. It can be a costly mistake (see CD Projekt Red) both financially and in reputation to upset the gaming community. It would be nice to see more innovative, creative games made out of passion and care for gaming culture with the potential for DLCs and microtransactions that add to the experience intended by the developers rather than with just the aim to add to their wallets.
      </p>
      <br />
      <p>
        Thanks for checking out our project and hopefully having a bit of fun along the way!
      </p>
      <p>
        Made by Sarah Evans and Mitchell Needham
      </p>
      <br />
      <p>
        P.S. You're welcome to have the domain name! It was available at the time and I (Mitch) lacked the creativity for a better name :)
      </p>
    </div>
  );
}
