import ProjectPage from "./components/pages/ProjectPage";
import { InteractionBoxes } from "./components/common/InteractionBox";
import InteractionBoxProvider from "./api/InteractionBoxContext";

import './App.scss';

function App() {
  return (
    <InteractionBoxProvider>
      <InteractionBoxes />
      <ProjectPage />
    </InteractionBoxProvider>
  );
}

export default App;
