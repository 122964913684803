import { v4 as uuidv4 } from 'uuid';

import thumbFortnite from '../../../images/Micro/fortnite.jpg';
import thumbCOD from '../../../images/Micro/cod.jpg';
import thumbAC from '../../../images/Micro/ac.jpg';
import thumbBF2 from '../../../images/Micro/bf2.jpg';


import lootbox1 from '../../../images/Micro/lb1.jpg';
import lootbox2 from '../../../images/Micro/lb2.jpg';

import bf1 from '../../../images/Micro/bf2-1.jpg';
import bf2 from '../../../images/Micro/bf2-2.jpg';

import fn1 from '../../../images/Micro/fn-1.jpg';
import fn2 from '../../../images/Micro/fn2-2.jpg';

export const CONTENT = [
  {
    id: uuidv4(),
    thumbnail: thumbCOD,
    main: (
      <div>
        <h2>
          Call of Duty: Black Ops IV
        </h2>
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: 'calc(100% - 230px)'}}>
            <p>
              To unlock new items players had to open <b>loot boxes</b> as opposed to unlocking them.
            </p>
            <p>
              The loot box odds were really against the player, the loot boxes you would earn in game would give you <b>1 item</b> and it was possible to get <b>duplicates</b> while the loot boxes you bought would give you <b>3 items</b> and there was no <b>chance of duplicates</b>.
            </p>
          </div>
          <img src={lootbox1} alt="" />
          
          <div>
            <img src={lootbox2} alt="" style={{ float: 'left' }} />
            <p>
              Within the loot boxes the player could unlock weapons and cosmetics. Cosmetics that could be unlocked were skins, sprays, outfits and voice lines.
            </p>
            <p>
              Being able to unlock weapons meant that there was a chance the player could <b>buy an advantage</b> against their opponents that could change the flow of the a match.
            </p>
            <p>
              Being able to unlock items like sprays and voice lines meant that when <b>earning</b> the loot boxes, the chance to get duplicates on these cosmetics makes the game feel <b>unfair and excessively grindy</b>.
            </p>
          </div>
          <iframe width="1000" height="600" src="https://www.youtube.com/embed/xWU1IAdr3rU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbBF2,
    main: (
      <div>
        <h2>
          Battlefront 2
        </h2>
        <p>
         When it was released it had a huge microtransaction problem, when buying the pre-order special edition you can unlock weapons which give you a <b>significant advantage in battles.</b>
        </p>
        <p>
          Then they had loot boxes which would give you a chance to unlock some items to help in the game. These are bought with in game currency which requires you to play approximately 15 games before being able to afford but you could by-pass this by buying the loot boxes with microtransactions.
        </p>
        <img src={bf1} style={{ width: '45%' }} alt="" />
        <img src={bf2} style={{ float: 'right', width: '45%' }} alt="" />
        <p>
          EA quickly realised this and took away the microtransactions but it was somewhat too late as some of the player base had an <b>unfair advantage</b> compared to new players or players who didn’t buy anything.
        </p>
        <div style={{ width: '560px', margin: '0 auto 0' }}>
          <iframe  width="560" height="315" src="https://www.youtube.com/embed/-pDcNJedoZE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbAC,
    main: (
      <div>
        <h2>
          Assassin’s Creed: Origins, Odyssey and Valhalla
        </h2>
        <p>
          These games have made the <b>game progression</b> purposely <b>slow</b> and grindy to incentivise players to <b>purchase</b> the ‘time saver packs’ which offer a 20% XP boost to allow the player to level up faster. This encouraged players who don’t have much time on their hands to spend money on the game.
        </p>
        <p>
          The player can also <b>purchase</b> in game currency and armour. In particular Valhalla had more armour sets to purchase than to unlock in the game.
        </p>
        <iframe style={{ display: 'block', margin: '0 auto 0' }} width="1000" height="600" src="https://www.youtube.com/embed/b0FXMB64g8w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    )
  },
  {
    id: uuidv4(),
    thumbnail: thumbFortnite,
    main: (
      <div>
        <h2>
          Fortnite
        </h2>
        <p>Is a free to play game with no pay-to-win elements. However, Fortnite does have a battle pass feature where the player can earn enough in game coins to be able to buy another battle pass for free. Earning enough of the coins does take some <b>dedication</b> so that Epic Games can encourage people to buy it but overall, that’s a nice feature to have.</p>
        <img src={fn1} style={{ width: '45%' }} alt="" />
        <img src={fn2} style={{ float: 'right', width: '45%' }} alt="" />
        <p>
          In Fortnite you can purchase skins to wear while you compete in the map. This is a way for players to voluntarily choose to support the game <b>without making it unfair</b> to other users who don’t purchase.
        </p>
        <p>
          Regarding money, Epic Games made a great game that puts little to no pressure on the player to buy anything unless they want to look cool. They’re constantly updating and keeping the game fresh.
        </p>
      </div>
    )
  }
];