import { useState } from 'react';
import axios from 'axios';
import './Compliments.scss';

export default function Compliments({ unlock, closeUI, redirect, canUnlock }) {
  const [compliment, setCompliment] = useState('');
  const [name, setName] = useState('');
  const [warning, setWarning] = useState(false);

  const submitCompliment = () => {
    if (compliment.length < 6) {
      setWarning(true)
      return;
    }

    axios
    .post(
      'https://phils-music-list.herokuapp.com/api/compliments/add',
      { message: compliment, sender: name }
    )
    .catch((err) => {
      console.log(err);
    });

    unlock();
    redirect();
    closeUI();
  }

  const changeCompliment = (val) => {
    setCompliment(val);
    if (compliment.length >= 6) {
      setWarning(false);
    }
  }

  if (!canUnlock) {
    return (
      <div className="compliment-container">
        <h3>
          Woah there, this content is premium
        </h3>
        <p style={{ margin: '0' }}>
          Come back after unlocking the other content.
        </p>
      </div>
    )
  }

  return (
    <div
      className="compliment-container"
    >
      <h3>You need to pay for this content</h3>
      <textarea
        placeholder="Pay with compliment here"
        value={compliment}
        onChange={(e) => changeCompliment(e.target.value)}
      />
      <input
        placeholder="From"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {warning &&
      (
        <p style={{ color: 'red' }}>
          Come on, is that even a compliment?
        </p>
      )}
      <div
        className="compliment-submit"
        role="button"
        tabIndex="0"
        onClick={() => submitCompliment()}
        onKeyDown={(e) => e.key === 'Enter' && submitCompliment()}
      >
        Submit
      </div>
    </div>
  )
}